<template>
  <div class="flex justify-between items-center">
    <app-filters
      @filters-applied="handleFiltersUpdated"
    />

    <app-geoloc-filters
      @submitted="handleGeolocUpdated"
    />
  </div>
</template>

<script setup>
import AppFilters from '@app/components/ui/AppFilters.vue'
import AppGeolocFilters from '@app/components/ui/AppGeolocFilters.vue'

const emits = defineEmits([
  'geoloc-updated',
  'filters-applied',
])

function handleGeolocUpdated() {
  emits('geoloc-updated')
}

function handleFiltersUpdated() {
  emits('filters-applied')
}
</script>
