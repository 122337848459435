<template>
  <app-address
    v-if="address"
    :line1="getAttributeTranslation(address.attributes?.line1)"
    :line2="getAttributeTranslation(address.attributes?.line2)"
    :zip="address.attributes?.zip"
    :city="getAttributeTranslation(address.attributes?.city)"
    :dependent-locality="getAttributeTranslation(address.attributes?.dependent_locality)"
    :administrative-area="getAttributeTranslation(address.attributes?.administrative_area)"
    :country="address.attributes?.country"
  />
</template>

<script setup>
import { computed } from 'vue'

import AppAddress from '@shared/components/ui/AppAddress.vue'
import useLocale from '@shared/hooks/locale'

const props = defineProps({
  // JSON API resource
  resource: {
    type: Object,
    default: () => ({}),
  },
})

const { getAttributeTranslation } = useLocale()

const address = computed(() => (
  props.resource?.relationships?.address
))
</script>
