import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'

/**
 * Used to open a printable modal
 *
 * @returns {{handleMapPrint: (function(*): boolean), openWindow: (function(*, *): Window)}}
 */
export default function usePrintMap() {
  const store = useStore()
  const { t } = useI18n()

  /**
   * Open a new modal for printing
   *
   * @param target
   * @param options
   * @returns {WindowProxy}
   */
  function openWindow(target, options) {
    const windowRef = window.open(null, target, options)

    if (!windowRef.opener) {
      windowRef.opener = window.self
    }

    windowRef.focus()
    return windowRef
  }

  /**
   * Execute print map
   *
   * @param id
   * @param title
   * @returns {boolean}
   */
  function handleMapPrint(id, title) {
    const element = window.document.getElementById(id)

    if (!element) {
      store.commit(
        'flashes/ADD_FLASH',
        { message: t('points_of_interest.print.element_not_found', { id }), type: 'danger' },
      )

      return false
    }

    const options = [
      'dialog=yes',
      'titlebar=yes',
      'scrollbars=yes',
    ]

    const modal = openWindow('_blank', options)
    const modalTitle = title ?? window.document.title
    const content = modalTemplate(modalTitle, element.outerHTML)

    modal.document.write(content)

    // Open modal, print window
    // and auto close
    setTimeout(() => {
      modal.document.close()
      modal.focus()
      modal.print()
      setTimeout(() => {
        modal.close()
      }, 1)
    }, 1000)

    return true
  }

  /**
   * HTML template for modal
   *
   * @param title
   * @param content
   * @returns {string}
   */
  function modalTemplate(title, content) {
    return `
      <html lang="en">
        <head>
          <title>${title}</title>
          <style>
            @media print {
              @page {
                size: landscape
              }

              .modal-content {
                display: flex;
                align-content: center;
              }
            }
            
            .gmap {
              border: none;
              width: 100%;
              min-height: 100%;
            }
          </style>
        </head>
        <body class="modal-content">
          ${content}
        </body>
      </html>
    `
  }

  return {
    openWindow,
    handleMapPrint,
  }
}
