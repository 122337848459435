<template>
  <app-overlay
    :show="show"
    @clicked="handleClose"
  >
    <app-modal @closed="handleClose">
      <div class="flex items-center justify-center flex-col">
        <img
          :src="resource.attributes.urls.md"
          class="rounded-md mb-4"
          :alt="t('validation.attributes.image')"
        >
        <p
          v-if="getImageCredits(resource)"
          class="text-sm"
        >
          {{ t('media.image.credits', { credits: getImageCredits(resource) }) }}
        </p>
      </div>
    </app-modal>
  </app-overlay>
</template>

<script setup>
import { useI18n } from 'vue-i18n'

import AppOverlay from '@shared/components/ui/AppOverlay.vue'
import AppModal from '@shared/components/ui/AppModal.vue'

defineProps({
  resource: {
    type: Object,
    default: () => ({}),
  },
  show: {
    type: Boolean,
    default: false,
  },
})

const emits = defineEmits([
  'closed',
])

const { t } = useI18n()

function handleClose() {
  emits('closed')
}
function getImageCredits(image) {
  return image.attributes.credits
}
</script>
