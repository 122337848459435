<template>
  <div
    class="relative"
    @click="handleVideoClick"
  >
    <video
      ref="video"
      :src="src"
      :controls="false"
      :autoplay="false"
      :loop="false"
      :muted="false"
      class="rounded-md shadow-lg shadow-theme-500/10 w-full h-auto"
      @ended="handleVideoEnded"
      @canplaythrough="handleVideoLoaded"
    />

    <div
      v-if="loading"
      class="absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 text-theme-500"
    >
      <!-- Spinner need a wrapper to stay centered properly -->
      <app-spinner
        size="3x"
      />
    </div>

    <app-button
      v-else-if="!playing"
      feature="play"
      :hide-icon="false"
      emphasis="low"
      hide-text
      :icon-props="{ classes: ['drop-shadow'], size: 'w-12' }"
      class="absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2"
    />
  </div>
</template>

<script setup>
import { ref } from 'vue'

import AppButton from '@shared/components/ui/AppButton.vue'
import AppSpinner from '@shared/components/ui/AppSpinner.vue'

defineProps({
  // file source
  src: {
    type: String,
    required: true,
  },
})

const video = ref() // template ref
const playing = ref(false)
const loading = ref(true)

function play() {
  playing.value = true
  video.value.play()
}

function pause() {
  playing.value = false
  video.value.pause()
}

function handleVideoClick() {
  if (playing.value) {
    pause()
  } else {
    play()
  }
}

function handleVideoEnded() {
  pause()
}

function handleVideoLoaded() {
  loading.value = false
}
</script>
