<template>
  <div>
    <button
      type="button"
      class="text-sm app-link flex items-center"
      @click="toggleFiltersDisplay"
    >
      <app-icon
        library="coolicon"
        name="slider_02"
        class="mr-2"
        size="text-lg"
      />

      {{ t('common.actions.filters') }}

      <span class="bg-theme-500 px-1 pt-0.5 rounded-full text-white text-xs ml-2 w-5 h-5">
        {{ store.getters['filters/getFiltersCount']('pointsOfInterest') }}
      </span>
    </button>

    <transition
      appear
      name="bottom-bar"
    >
      <aside
        v-show="showFilters"
        class="z-40 fixed bottom-0 left-0 w-screen h-screen-3/4 shadow bg-body p-4 overflow-y-scroll"
      >
        <div class="flex flex-col justify-between">
          <div class="flex justify-between items-center mb-4">
            <app-sub-heading>
              {{ t('common.actions.filters') }}
            </app-sub-heading>

            <app-button
              feature="close"
              color="theme"
              emphasis="low"
              padding="py-1"
              :hide-text="true"
              :hide-icon="false"
              @click="handleClose"
            />
          </div>
        </div>

        <div>
          <form-group
            name="point_of_interest_category_id"
            :label="t('validation.attributes.category')"
            type="select"
            margins="mb-2"
            :form-control-props="{
              clearable: true,
              filterable: false,
              options: handleCategoriesSearch,
            }"
            @changed="handleCategoryFilterChange"
          />

          <div class="mb-3">
            <form-group
              ref="selectTags"
              name="tags_ids"
              type="select"
              :label="t('form.labels.points_of_interest_filters.tags')"
              margins="mb-2"
              :form-control-props="{
                filterable: false,
                mode: 'tags',
                options: handleTagsSearch,
              }"
              @changed="(value) => handleFilterChange('tags_ids', value)"
            />

            <div
              v-if="form.values.tags_ids?.length >= 2"
              class="flex items-center"
            >
              <form-group
                name="tags_mode"
                type="radio"
                :label="false"
                margins=""
                :form-control-props="{
                  options: tagsModeOptions,
                  optionsDirection: 'horizontal',
                }"
                @changed="(value) => handleFilterChange('tags_mode', value)"
              />

              <app-help-button class="ml-4">
                <p>
                  {{ t('points_of_interest.search.tags_mode.all_help') }}
                </p>

                <p>
                  {{ t('points_of_interest.search.tags_mode.one_help') }}
                </p>
              </app-help-button>
            </div>
          </div>

          <form-group
            name="creator_id"
            type="radio"
            margins="mb-2"
            :label="t('points_of_interest.search.creator_id')"
            :form-control-props="{
              options: creatorIdOptions,
            }"
            @changed="(value) => handleFilterChange('creator_id', value)"
          />

          <div class="flex items-center justify-end">
            <app-button
              feature="reset"
              color="red"
              emphasis="low"
              class="mr-6"
              @click="handleResetFilters"
            />

            <app-button
              feature="save"
              @click="handleFiltersApplied"
            />
          </div>
        </div>
      </aside>
    </transition>

    <app-overlay
      :show="showFilters"
      z-index="20"
      @clicked="handleClose"
    />
  </div>
</template>

<script setup>
import { ref, inject } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { isNil, uniqBy } from 'lodash'

import {
  fetchPointOfInterestCategories,
  fetchTags,
} from '@shared/http/api'
import useFilters from '@shared/hooks/filters'
import useLocale from '@shared/hooks/locale'
import useSelectOptions from '@shared/hooks/form/selectOptions'
import AppOverlay from '@shared/components/ui/AppOverlay.vue'
import AppSubHeading from '@app/components/ui/AppSubHeading.vue'
import AppButton from '@shared/components/ui/AppButton.vue'
import AppHelpButton from '@shared/components/ui/AppHelpButton.vue'
import AppIcon from '@shared/components/ui/AppIcon.vue'
import FormGroup from '@shared/components/form/FormGroup.vue'

const emits = defineEmits([
  'filters-applied',
])

const store = useStore()
const { t } = useI18n()

const { getAttributeTranslation } = useLocale()

const showFilters = ref(false)

function toggleFiltersDisplay() {
  showFilters.value = !showFilters.value
}

// ---------- CATEGORIES ----------

// Return categories options used in select control,
// based on categories resources
function formatCategoriesOptions(categories) {
  return categories?.map((categoryResource) => ({
    label: getAttributeTranslation(categoryResource.attributes?.label),
    value: categoryResource.id,
  })) ?? []
}

const initialCategoriesSearch = ref(true)
const category = ref(null)
const selectTags = ref()

function handleCategoriesSearch(searchKeywords) {
  return new Promise((resolve) => {
    let options = []

    if (initialCategoriesSearch.value) {
      initialCategoriesSearch.value = false

      if (store.state.filters.pointsOfInterest?.point_of_interest_category_id) {
        // Provide an option with the resource's relationship value
        options.push(formatCategoriesOptions([store.state.filters.pointsOfInterest.point_of_interest_category_id]))
      }
    }

    fetchPointOfInterestCategories({
      'filter[label]': searchKeywords,
      'order': 'alpha',
    })
      .then((e) => {
        // Get the new options from the response
        options = options.concat(formatCategoriesOptions(e.data.data))

        // Avoid updating var after each select change
        if (category.value) {
          category.value = e.data.data
        }
      })
      .finally(() => {
        resolve(uniqBy(options, 'value'))
      })
  })
}

function handleCategoryFilterChange(value) {
  handleFilterChange('point_of_interest_category_id', value)

  // Reset tags' filter, because it depends on selected category
  form.value.setFieldValue('tags_ids', [])

  // Refresh tags' options, to let it adapt to the selected category
  selectTags.value.$refs.formControlRef.$refs.multiselect.refreshOptions()
}

// ---------- FILTERS ----------

const form = inject('pointsOfInterestIndexFiltersForm')

const {
  handleResetFilters: handleResetFiltersHook,
  handleFiltersApplied: handleFiltersAppliedHook,
  handleFilterChange,
} = useFilters('pointsOfInterest', form.value, { emits })

const {
  creatorIdOptions,
  tagsModeOptions,
} = useSelectOptions()

function handleFiltersApplied() {
  toggleFiltersDisplay()
  handleFiltersAppliedHook()
}

function handleClose() {
  toggleFiltersDisplay()
}

function handleResetFilters() {
  toggleFiltersDisplay()
  handleResetFiltersHook()
}

// Return tags
function formatTagsOptions(tags) {
  return tags?.map((tagResource) => ({
    label: getAttributeTranslation(tagResource.attributes?.label),
    value: tagResource.id,
  })) ?? []
}

const initialTagsSearch = ref(true)
const tags = ref([])

function handleTagsSearch(searchKeywords) {
  return new Promise((resolve) => {
    let options = []
    const params = {}

    if (initialTagsSearch.value) {
      initialTagsSearch.value = false
    }

    // Search keywords
    if (!isNil(searchKeywords)) {
      params.search = searchKeywords
    }

    // Filter by category
    if (!isNil(store.state.filters.pointsOfInterest?.point_of_interest_category_id)) {
      params.categoryId = store.state.filters.pointsOfInterest?.point_of_interest_category_id
    }

    fetchTags(params)
      .then((e) => {
        // Get the new options from the response
        options = options.concat(formatTagsOptions(e.data.data))

        // Avoid updating var after each select change
        if (tags.value.length <= 0) {
          tags.value = e.data.data ?? []
        }
      })
      .finally(() => {
        resolve(uniqBy(options, 'value'))
      })
  })
}
</script>
