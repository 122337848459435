<template>
  <div class="py-6 px-8 w-80 h-10 bg-theme-500 text-white rounded-full flex items-center">
    <div class="w-1/12 mr-1.5 leading-none">
      <!-- Play state -->
      <font-awesome-icon
        v-if="loading"
        spin
        icon="circle-notch"
      />

      <span
        v-else-if="playDelaying"
        class="font-bold text-2xl"
        @click="resetDelay"
      >
        {{ playDelaySecondsRemaining }}
      </span>

      <app-icon
        v-else-if="!playing"
        :classes="['cursor-pointer']"
        library="local"
        name="play"
        size="w-5.5"
        @click="handlePlayClick"
      />

      <font-awesome-icon
        v-else
        class="cursor-pointer"
        icon="pause"
        size="2x"
        @click="handlePauseClick"
      />
    </div>

    <!-- Time slider -->
    <div class="flex-1 flex items-center text-xs">
      <div class="mr-1.5">
        <span class="w-6 inline-block">
          {{ currentTimeDisplayed }}
        </span>

        <span class="w-6 inline-block">
          <template v-if="!loading">
            /&nbsp;{{ durationDisplayed }}
          </template>
        </span>
      </div>

      <div
        class="bg-white h-1 flex-1 cursor-pointer"
        @click="handleTimeSliderClick"
      >
        <div
          class="bg-theme-700 h-full"
          :style="`width: ${timeSliderWidth}`"
        />
      </div>
    </div>

    <!-- Volume -->
    <div class="mr-1 ml-2 w-1/12">
      <font-awesome-icon
        v-if="!muted"
        class="cursor-pointer"
        icon="volume-up"
        size="lg"
        @click="handleVolumeMute"
      />

      <font-awesome-icon
        v-else
        class="cursor-pointer"
        icon="volume-mute"
        size="lg"
        @click="handleVolumeUnmute"
      />
    </div>

    <!-- Settings -->
    <div
      class="ml-2 w-1/12 relative"
    >
      <span
        class="cursor-pointer font-bold"
        @click="toggleSpeedMenu"
      >
        {{ audio.playbackRate }}x
      </span>

      <ul
        v-if="speedMenuDisplayed"
        v-clicked-outside="closeSpeedMenu"
        class="absolute bottom-8 text-left right-0 py-2.5 px-3 bg-theme-500 rounded-2lg"
      >
        <li
          v-for="(option, i) in speedOptions"
          :key="i"
          class="cursor-pointer my-1.5 font-bold whitespace-nowrap"
          @click="handleSpeedChange(option.value)"
        >
          {{ capitalize(option.label) }}
          {{ option.value }}x
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
import {
  ref,
  computed,
  onUnmounted,
  watch,
} from 'vue'
import { useI18n } from 'vue-i18n'
import { capitalize } from 'lodash'

import useSelectOptions from '@shared/hooks/form/selectOptions'
import AppIcon from '@shared/components/ui/AppIcon.vue'

const props = defineProps({
  // File source
  src: {
    type: String,
    required: true,
  },
  // Play should be delay or not
  delayPlaying: {
    type: Boolean,
    default: false,
  },
})

const { locale } = useI18n()
const { speedOptions } = useSelectOptions()

const audio = computed(() => new Audio(props.src)) // audio object
const loading = ref(true) // audio loading or not
const duration = ref(null) // audio's duration
const currentTime = ref(0) // audio's current time
const currentTimeInterval = ref(null) // Interval object to increase current time
const playing = ref(false) // audio playing or not
const playDelaying = ref(false) // audio play delaying or not
const playDelaySecondsTotal = 3
const playDelaySecondsSpent = ref(0)
const playDelayInterval = ref(null)
const muted = ref(false) // audio muted or not
const speedMenuDisplayed = ref(false) // display settings or not

// Config audio at creation
handleAudioChange()

// ---------- PLAY STATE ----------

function handlePlayClick() {
  if (props.delayPlaying) {
    delayPlay()
  } else {
    play()
  }
}

function play() {
  playDelaying.value = false
  playing.value = true
  audio.value.play()
  increaseCurrentTime()
}

// Wait before actually playing
// This gives the traveller enough time to hand its phone to another person
function delayPlay() {
  // In order to prevent browser (like Safari) to interpret the delayed play as an auto play and forbid it:
  // Immediately play, then pause the audio, so the browser understand that audio played is due to an user action
  audio.value.play()
  audio.value.pause()

  playDelaying.value = true

  playDelayInterval.value = setInterval(() => {
    playDelaySecondsSpent.value += 1

    if (playDelaySecondsSpent.value >= playDelaySecondsTotal) {
      resetDelay()
      play()
    }
  }, 1000)
}

// Seconds remaining before play
const playDelaySecondsRemaining = computed(() => (
  playDelaySecondsTotal - playDelaySecondsSpent.value
))

function resetDelay() {
  playDelaying.value = false
  playDelaySecondsSpent.value = 0
  clearInterval(playDelayInterval.value)
}

function handlePauseClick() {
  pause()
}

function pause() {
  playing.value = false
  audio.value.pause()
  clearInterval(currentTimeInterval.value)
}

// ---------- VOLUME ----------

function handleVolumeMute() {
  audio.value.muted = true
  muted.value = true
}

function handleVolumeUnmute() {
  audio.value.muted = false
  muted.value = false
}

// ---------- TIME ----------

const currentTimeDisplayed = computed(() => (
  Math.round(currentTime.value).toLocaleString(locale, { minimumIntegerDigits: 2 })
))

const durationDisplayed = computed(() => {
  if (durationNotDefinedYet.value) {
    return '--'
  }
  return Math.round(duration.value).toLocaleString(locale, { minimumIntegerDigits: 2 })
})

function resetCurrentTime() {
  currentTime.value = 0
  audio.value.currentTime = 0
}

function increaseCurrentTime() {
  currentTimeInterval.value = setInterval(() => {
    if (playing.value) {
      currentTime.value += (0.1 * audio.value.playbackRate)
    }

    // reset the audio when it has been fully played
    if (currentTime.value > duration.value) {
      resetAudio()
    }
  }, 100)
}

// Calculate and return the time slider's width percentage
const timeSliderWidth = computed(() => {
  if (durationNotDefinedYet.value) {
    return '0%'
  }
  return `${(currentTime.value / Math.round(duration.value)) * 100}%`
})

const durationNotDefinedYet = computed(() => (
  // - Infinity could occur when an audio has just been recorded
  // - null could occur when an audio has not fully loaded yet
  [Infinity, null].includes(duration.value)
))

// Adapt the time slider when it has been clicked
function handleTimeSliderClick(e) {
  if (!durationNotDefinedYet.value) {
    const sliderWidth = e.currentTarget.offsetWidth
    const sliderOffsetXClicked = e.offsetX
    const timeClickedRatio = (sliderOffsetXClicked / sliderWidth)
    const timeSeeked = timeClickedRatio * duration.value

    currentTime.value = timeSeeked
    audio.value.currentTime = timeSeeked
  }
}

// ---------- SPEED ----------

function handleSpeedChange(value) {
  audio.value.playbackRate = value
  closeSpeedMenu()
}

function toggleSpeedMenu() {
  speedMenuDisplayed.value = !speedMenuDisplayed.value
}

function closeSpeedMenu() {
  speedMenuDisplayed.value = false
}

// ---------- LIFECYCLES ----------

// Config audio when it changes
watch(audio, () => {
  handleAudioChange()
})

function resetAudio() {
  pause()
  resetCurrentTime()
}

function handleAudioChange() {
  loading.value = true
  resetAudio()
  audio.value.load()
  audio.value.volume = 1 // make sure the media volume is at 100%
  audio.value.playbackRate = 1 // reset speed

  // retrieve audio's duration metadata
  // check if audio metadata has load, or wait for it to load
  duration.value = null
  if (audio.value.readyState > 0) {
    duration.value = audio.value.duration
  } else {
    audio.value.addEventListener('loadeddata', () => {
      duration.value = audio.value.duration
    })
  }
  audio.value.addEventListener('durationchange', () => {
    duration.value = audio.value.duration
  })

  // check if audio has fully load, or wait for it to load
  if (audio.value.readyState === 4) {
    loading.value = false
  } else {
    audio.value.addEventListener('canplaythrough', () => {
      loading.value = false
    })
  }
}

onUnmounted(() => {
  audio.value.pause()
})
</script>
