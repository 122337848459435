<template>
  <div
    class="w-full"
  >
    <div
      data-placeholder
      class="poi-top-gallery h-60 w-screen bg-gray-200 mb-4 -mx-4 -mt-4 rounded-b-2xl shadow-md"
    />

    <div
      data-placeholder
      class="h-6 mb-1 w-40 bg-gray-200"
    />

    <div
      data-placeholder
      class="mb-1 h-4 w-40 bg-gray-200"
    />

    <div
      data-placeholder
      class="mb-2 h-4 w-40 bg-gray-200"
    />

    <div
      v-for="i in 10"
      :key="i"
      data-placeholder
      class="h-5 mb-1 w-full bg-gray-200"
    />

    <div class="mb-5" />

    <div
      v-for="i in 6"
      :key="i"
      data-placeholder
      class="mb-1 h-4 w-40 bg-gray-200"
    />
  </div>
</template>

<script setup>
import { onBeforeUnmount } from 'vue'
import { useStore } from 'vuex'

const store = useStore()
store.commit('header/SET_TRANSPARENT_BACKGROUND', true)

onBeforeUnmount(() => {
  store.commit('header/SET_TRANSPARENT_BACKGROUND', false)
})
</script>
