<template>
  <p
    v-if="isExpired"
    class="p-2 px-3 font-bold text-xl text-red-500"
  >
    {{ t('points_of_interest.pages.show.opening_hours.expired') }}
  </p>
  <div class="text-xl font-bold text-center mb-6">
    {{ t('validation.attributes.opening_hours') }}
  </div>

  <div
    v-for="(period, i) in openingHours"
    :key="i"
  >
    <hr class="py-2">
    <opening-hours-period
      :period="period"
      :locale="locale"
    />
  </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n'

import OpeningHoursPeriod from '@shared/components/resources/opening_hours/OpeningHoursPeriod.vue'

defineProps({
  // Opening hours object
  openingHours: {
    type: Object,
    default: () => ({}),
  },
  // Locale to display the opening hours
  locale: {
    type: String,
    default: null,
  },
  isExpired: {
    type: Boolean,
    default: false,
  },
})

const { t } = useI18n()
</script>
